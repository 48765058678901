import { Card } from "materia"
import type { ReactNode } from "react"
import { UserProfileButtonConnected } from "../UserProfileButton"
import { YourJourneysListConnected } from "../YourJourneysList"
import { YourJourneysStatsConnected } from "../YourJourneysStats"

import s from "./styles.module.scss"

export const JOURNEY_LIST_SIZE = 7

export const JourneyNavigation = () => {
  return (
    <JourneyNavigation.Wrapper>
      <UserProfileButtonConnected />

      <JourneyNavigation.Section>
        <YourJourneysStatsConnected />
      </JourneyNavigation.Section>

      <YourJourneysListConnected size={JOURNEY_LIST_SIZE} />
    </JourneyNavigation.Wrapper>
  )
}

JourneyNavigation.Wrapper = ({ children }: { children: ReactNode }) => (
  <div className={s.temp}>
    <Card borderRadius="24">
      <div className={s.journeyNavigation}>{children}</div>
    </Card>
  </div>
)

JourneyNavigation.Section = ({ children }: { children: ReactNode }) => (
  <div className={s.journeyNavigation__section}>{children}</div>
)
