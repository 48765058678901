import checkmark from "assets/icons/checkmark.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useTranslation } from "react-i18next"
import { Stars } from "../Stars"
import { query } from "./gql"
import s from "./styles.module.scss"

export const YourJourneysStatsConnected = () => {
  // We don't have any GraphQL query that gets this data aggregated
  // on the backend, so we'll temporarily calculate it on the frontend.
  // No company should have more than 1k journeys so it should be safe
  // but it's not the best practice.
  const { data, error, isPending } = useGraphqlQuery(query, {
    page: 1,
    limit: 1000,
  })

  const journeys = data?.productCategories.nodes ?? []

  return (
    <YourJourneysStats
      isLoading={isPending}
      error={error}
      journeys={journeys}
    />
  )
}

type JourneyProgress = {
  certified: boolean
  starsCount: number
}
type YourJourneyStatsProps = {
  isLoading: boolean
  error: unknown
  journeys: { progress: JourneyProgress }[]
}
export const YourJourneysStats = ({
  isLoading,
  error,
  journeys,
}: YourJourneyStatsProps) => {
  const { t } = useTranslation()

  const userStars = journeys.reduce(
    (sum, curr) => sum + (curr.progress.starsCount ?? 0),
    0
  )
  const completedCount = journeys.filter((j) => j.progress.certified).length
  const totalCount = journeys.length

  const getContent = () => {
    if (isLoading) return <YourJourneysStatsSkeleton />

    // TODO Implement error state
    if (error) return <YourJourneysStatsError error={error} />

    return (
      <>
        <div className={s.journeyStats__completed}>
          <div className={s.journeyStats__completedIcon}>
            <img src={checkmark} alt="" />
          </div>
          <span>
            {completedCount}/{totalCount}
          </span>
        </div>
        <div className={s.journeyStats__stars}>
          <Stars max={1} count={1} size="small" />
          <span>{userStars}</span>
        </div>
      </>
    )
  }

  return (
    <div className={s.journeyStats}>
      <div className={s.journeyStats__title}>{t("journey.YOUR_JOURNEYS")}</div>
      <div className={s.journeyStats__wrapper}>{getContent()}</div>
    </div>
  )
}

const YourJourneysStatsSkeleton = () => {
  return (
    <>
      <div className={s.journeyStats__skeleton} />
      <div className={s.journeyStats__skeleton} />
    </>
  )
}

const YourJourneysStatsError = ({ error }: { error: unknown }) => {
  return (
    <div className={s.error}>
      <div className={s.journeyStats__completed}>
        <div className={s.journeyStats__completedIcon}>
          <img src={checkmark} alt="" />
        </div>
        <span>?/?</span>
      </div>
      <div className={s.journeyStats__stars}>
        <Stars max={1} count={1} size="small" />
        <span>?</span>
      </div>
    </div>
  )
}
