import { type PropsWithChildren } from "react"
import { DialogContext, useContextValue } from "./context"

export const DialogContainer = ({ children }: PropsWithChildren) => {
  const { context, setBackgroundElement, setModalElement } = useContextValue()

  return (
    <DialogContext.Provider value={context}>
      <div
        style={{ height: "100%" }}
        ref={setBackgroundElement}
        data-display-name="dialog-background"
      >
        {children}
      </div>
      <div ref={setModalElement} data-display-name="dialog-container" />
    </DialogContext.Provider>
  )
}
