import { useState } from "react"
import { useCurrentCompanyContext } from "../AppShell/CurrentCompanyProvider"
import { useCurrentUserContext } from "../AppShell/CurrentUserProvider"
import { Chat } from "./components/Chat"
import { usePostFlowiseQuestionStream } from "./hooks/useFlowiseQuestionStream"

export type ChatMessage = {
  messageType: "Question" | "Response" | "Error"
  text: string
}

// TODO: chat and suggestions should be dynamic
// This is only a temporary hard coded solution while we're waiting for back-end to support all of this
const attensiUniversityChatConfig = {
  companyNameKey: "attensi_university",
  flowId: "91c85855-e251-4a27-ab9c-5199e4da54ca",
  title: "Assistant",
  questionSuggestions: [
    "How do I create great minigames?",
    "How do I create a Fast course?",
    "What is Attensi Insights?",
    "How do I write dialogues?",
  ],
}

const tomasMyklebustChatConfig = {
  companyNameKey: "tomas_myklebust",
  flowId: "5085a37c-d417-4194-8327-a9e3ff365fab",
  title: "Myklebust AI",
  questionSuggestions: [
    "Hvordan kan jeg bruke en søvndagbok for å kartlegge søvnen min?",
    "Hvordan påvirker aktiviteten min på dagtid hvilke søvnfaser jeg tilbringer mest tid i?",
    "Hvordan kan sovemedisiner og alkohol forstyrre søvnmønsteret mitt?",
    "Hvordan kan søvn påvirke min læringsprosess og hukommelse?",
  ],
}

export const AIChat = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const {
    currentCompany: { nameKey },
  } = useCurrentCompanyContext()
  const [messages, setMessages] = useState<Array<ChatMessage>>([])
  const { currentUser } = useCurrentUserContext()
  const chatConfig =
    nameKey === "tomas_myklebust"
      ? tomasMyklebustChatConfig
      : attensiUniversityChatConfig // Temporary hard coded solution while we're still waiting for the back-end to support this

  const { isLoading, askQuestion } = usePostFlowiseQuestionStream(
    chatConfig.flowId
  )

  const handleSubmit = async (question: string) => {
    // Append the question - and a response that we can update while streaming
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: question, messageType: "Question" },
      { text: "", messageType: "Response" },
    ])

    await askQuestion(
      question,
      (chunk) => {
        // Append the incoming stream chunks of text to the current chat response
        setMessages((prevMessages) => {
          const messagesCopy = [...prevMessages]
          const indexOfLastMessage = messagesCopy.length - 1
          const lastMessageCopy = messagesCopy[indexOfLastMessage]

          if (!lastMessageCopy) return prevMessages
          messagesCopy.splice(indexOfLastMessage, 1, {
            messageType: "Response",
            text: lastMessageCopy.text + chunk.text,
          })

          return messagesCopy
        })
      },
      (errorMessage) => {
        // Append error message as a special (error) chat response
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: errorMessage, messageType: "Error" },
        ])
      }
    )
  }

  return (
    <Chat
      isOpen={isOpen}
      onClose={onClose}
      title={chatConfig.title}
      userAvatar={currentUser.avatar}
      questionSuggestions={chatConfig.questionSuggestions}
      onSubmit={handleSubmit}
      messages={messages}
      isPendingAnswer={isLoading}
    />
  )
}
