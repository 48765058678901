import s from "./styles.module.scss"

export const YourJourneysListSkeleton = () => {
  return (
    <div className={s.journeyList}>
      <SkeletonItem showConnector />
      <SkeletonItem showConnector />
      <SkeletonItem showConnector />
      <SkeletonItem showConnector />
      <SkeletonItem />
    </div>
  )
}

type SkeletonItemProps = { showConnector?: boolean }
const SkeletonItem = ({ showConnector = false }: SkeletonItemProps) => (
  <div className={s.journeyItem}>
    <div className={s.journeyItem__button}>
      <div className={s.journeyItem__thumbnail} />
      <div className={s.skeleton__journeyItemTitle} />
    </div>

    {showConnector && <div className={s.journeyItem__connector} />}
  </div>
)
