import { FiestaLink } from "utility-components"
import { useCurrentCompanyContext } from "../AppShell/CurrentCompanyProvider"
import { useCurrentUserContext } from "../AppShell/CurrentUserProvider"
import { Avatar } from "../Avatar"

import s from "./styles.module.scss"

export const UserProfileButtonConnected = () => {
  const { currentUser, isCurrentUserPending } = useCurrentUserContext()
  const { currentCompany, isCurrentCompanyPending } = useCurrentCompanyContext()

  const isLoading = isCurrentCompanyPending && isCurrentUserPending

  // We don't need to handle error state of this component.
  // It gets all its data from current user/company contexts, and since those
  // return crucial data needed for the app to work, they handle errors themselves.

  const { avatar, avatarTitle, displayName, position } = currentUser
  const { portalFeatureFlags } = currentCompany
  const isUserProfileEnabled = !portalFeatureFlags.includes(
    "disable_user_profile"
  )

  if (!isUserProfileEnabled) return null

  return (
    <UserProfileButton
      isLoading={isLoading}
      userData={{ avatar, avatarTitle, displayName, position }}
    />
  )
}

type UserProfileButtonProps = {
  isLoading: boolean
  userData: {
    avatar: string
    avatarTitle: string
    displayName: string | null
    position: string | null
  }
}
export const UserProfileButton = ({
  isLoading,
  userData,
}: UserProfileButtonProps) => {
  const { avatar, avatarTitle, displayName, position } = userData

  if (isLoading) return <UserProfileButtonSkeleton />

  return (
    <FiestaLink
      className={s.user}
      to="/me"
      // TODO https://jira.attensi.com/browse/WEB-19703
      // We do search={{}} to remove search params from the URL
      // to avoid unexpected behaviours
      search={{}}
    >
      <Avatar alt={avatarTitle} image={avatar} size="large" />

      <div className={s.user__textWrapper}>
        {displayName && <h2 className={s.user__name}>{displayName}</h2>}
        {position && <div className={s.user__position}>{position}</div>}
      </div>
    </FiestaLink>
  )
}

const UserProfileButtonSkeleton = () => {
  return (
    <div className={s.skeleton}>
      <Avatar.Skeleton size="large" />
      <div className={s.skeleton__textWrapper}>
        <div className={s.skeleton__name} />
        <div className={s.skeleton__position} />
      </div>
    </div>
  )
}
