import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useQueryParam } from "hooks"
import { ProgressBar, useWindowWidth } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import { toPercent } from "utils"
import { Stars } from "../../Stars"
import { UserInfoHeaderError } from "./error"
import { query } from "./gql"
import { UserInfoHeaderLoading } from "./loading"
import s from "./styles.module.scss"

export const UserInfoHeader = () => {
  const { isBigWindow } = useWindowWidth()
  const { data, error, isPending } = useGraphqlQuery(
    query,
    {},
    { enabled: !isBigWindow }
  )
  const { t } = useTranslation()

  const [mandatoryQuery] = useQueryParam("mandatory")
  const [incompleteQuery] = useQueryParam("incomplete")

  const isMandatory = mandatoryQuery === "true"
  const isIncomplete = incompleteQuery === "true"

  if (isBigWindow) return
  if (isPending) return <UserInfoHeaderLoading />
  if (error)
    return (
      <UserInfoHeaderError
        isMandatory={isMandatory}
        isIncomplete={isIncomplete}
        error={error}
      />
    )

  const {
    scenarioCompleteCount,
    scenarioCompleteMax,

    scenarioMandatoryCompleteCount,
    scenarioMandatoryCompleteMax,

    productCompleteMax,
    productCompleteCount,

    productStarsCount,
    productStarsMax,

    productMandatoryCompleteCount,
    productMandatoryCompleteMax,

    productMandatoryStarsCount,
    productMandatoryStarsMax,
  } = data.userProgressSummary

  const incompleteCount = productCompleteMax - productCompleteCount

  const value = isMandatory ? productMandatoryStarsCount : productStarsCount
  const maxValue = isMandatory ? productMandatoryStarsMax : productStarsMax

  return (
    <div className={s.header}>
      <div className={s.row}>
        <div className={s.stars}>
          <Stars
            count={1}
            max={1}
            size="extraSmall"
            ariaLabel={`${productStarsCount} of ${productStarsMax}`}
          />
          <div className={s.stars__value}>
            {value}/{maxValue}
          </div>
        </div>
        <Completed
          label={t("user.COMPLETED")}
          value={
            isMandatory ? scenarioMandatoryCompleteCount : scenarioCompleteCount
          }
          maxValue={
            isMandatory ? scenarioMandatoryCompleteMax : scenarioCompleteMax
          }
          isActive={false}
        />
        <Filter
          label={t("user.MANDATORY")}
          value={`${productMandatoryCompleteCount}/${productMandatoryCompleteMax}`}
          isVisible={productMandatoryCompleteMax > 0}
          link={{ to: "/for-you", search: { mandatory: !isMandatory } }}
          isActive={isMandatory}
          variant="mandatory"
        />
        <Filter
          label={t("user.INCOMPLETE")}
          value={incompleteCount}
          isVisible={true}
          link={{ to: "/for-you", search: { incomplete: !isIncomplete } }}
          isActive={isIncomplete}
          variant="incomplete"
        />
      </div>
      <Progress
        value={
          isMandatory ? scenarioMandatoryCompleteCount : scenarioCompleteCount
        }
        maxValue={
          isMandatory ? scenarioMandatoryCompleteMax : scenarioCompleteMax
        }
        variant={isMandatory ? "mandatory" : "completed"}
      />
    </div>
  )
}

const Completed = ({
  value,
  maxValue,
  label,
  isActive,
}: {
  value: number | string
  maxValue: number | string
  label: string
  isActive: boolean
}) => {
  return (
    <div className={modifiers(s, "completed", { isActive })}>
      <img className={s.completed__icon} src={checkmarkCircleGreen} alt="" />
      <div className={s.completed__value}>
        {toPercent(Number(value), Number(maxValue))}%
      </div>
      <div className={s.completed__label}>{label}</div>
    </div>
  )
}

const Filter = ({
  label,
  value,
  link,
  isVisible,
  isActive,
  variant,
}: {
  value: string | number
  label: string
  link: FiestaLinkProps
  isVisible: boolean
  isActive: boolean
  variant: "mandatory" | "incomplete"
}) => {
  if (!isVisible) return null

  return (
    <FiestaLink
      className={modifiers(s, "filter", { isActive }, variant)}
      {...link}
    >
      <div className={s.filter__value}>{value}</div>
      <div className={s.filter__label}>{label}</div>
    </FiestaLink>
  )
}

const Progress = ({
  value,
  maxValue,
  variant,
}: {
  value: number
  maxValue: number
  variant: "mandatory" | "completed"
}) => (
  <div className={s.progress}>
    <ProgressBar
      value={value}
      maxValue={maxValue}
      color={variant === "completed" ? "green-30" : "purple-40"}
      backgroundColor={variant === "completed" ? "green-10" : "marble"}
    />
  </div>
)
