import { auth } from "auth"
import { debounce } from "lodash-es"
import { Button, Modal, useErrorSentryLog } from "materia"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { DEFAULT_TITLE, usePageTitle } from "../../tools/usePageTitle"
import { IS_APP } from "../../vuplex/constants"
import { useCurrentCompanyContext } from "../AppShell/CurrentCompanyProvider"
import { useCurrentUserContext } from "../AppShell/CurrentUserProvider"
import { Avatar } from "../Avatar"
import {
  canUseSharedDeviceFlag,
  mustUseSharedDeviceFlag,
  useSharedDeviceStore,
} from "./store"
import { useBackgroundTimeout } from "./useBackgroundTimeout"

import s from "./styles.module.scss"

const TRACKED_EVENTS = [
  "click",
  "keydown",
  "mousemove",
  "scroll",
  "touchstart",
  "touchend",
]

export const SharedDeviceInactivityTrackerConnected = () => {
  const { isSharedDevice, isPlayingAttensiProduct } = useSharedDeviceStore()

  const { currentUser, isError, isSharedDeviceEnabled } = useCompanySettings()

  const isTrackingEnabled =
    isSharedDevice &&
    isSharedDeviceEnabled &&
    !(IS_APP || isPlayingAttensiProduct || isError)

  const { avatar, avatarTitle, displayName } = currentUser

  if (isTrackingEnabled)
    return (
      <SharedDeviceInactivityTrackerModalWrapper
        avatar={avatar}
        avatarTitle={avatarTitle}
        displayName={displayName}
      />
    )
}

const SharedDeviceInactivityTrackerModalWrapper = ({
  avatar,
  avatarTitle,
  displayName,
}: {
  avatar: string
  avatarTitle: string
  displayName: string | null
}) => {
  const {
    isShowingInactiveModal,
    closeInactivityModal,
    onLogout,
    timeUntilLogout,
  } = useTracker()

  return (
    <>
      {isShowingInactiveModal && (
        <SharedDeviceInactivityModal
          avatar={avatar}
          avatarTitle={avatarTitle}
          displayName={displayName}
          timeUntilLogout={timeUntilLogout}
          onLogout={onLogout}
          onCloseInactivityModal={closeInactivityModal}
        />
      )}
    </>
  )
}

export const SharedDeviceInactivityModal = ({
  avatar,
  avatarTitle,
  displayName,
  timeUntilLogout,
  onLogout,
  onCloseInactivityModal,
}: {
  avatar: string
  avatarTitle: string
  displayName: string | null
  timeUntilLogout: number
  onLogout: () => void
  onCloseInactivityModal: () => void
}) => {
  const { t } = useTranslation()

  const signoutInMS = formatMilliseconds(timeUntilLogout)

  useTitleNotification(timeUntilLogout)

  return (
    <Modal onClose={() => {}} ariaLabel="User inactive">
      <div className={s.sharedDeviceInactivityTrackerModal}>
        <Avatar alt={avatarTitle} image={avatar} size="extraLarge" />
        <div className={s.sharedDeviceInactivityTrackerModal__title}>
          {t("activityTracker.IS_THIS_YOU")}
        </div>
        <div className={s.sharedDeviceInactivityTrackerModal__description}>
          <p>
            {t("activityTracker.LOGGED_IN_AS", {
              user: displayName,
            })}
          </p>
          <p>
            {t("activityTracker.INACTIVE_TIME", {
              user: displayName,
              time: signoutInMS,
            })}
          </p>
        </div>
        <Button theme="blue" onClick={onLogout}>
          {/* {t("activityTracker.LOG_OUT")} */}
          {/* This will be changed later when translation is done, needs to be done for starbucks now */}
          LOG OUT
        </Button>
        <Button theme="cancel" onClick={onCloseInactivityModal}>
          {/* {t("activityTracker.RESET")} */}
          {/* This will be changed later when translation is done, needs to be done for starbucks now */}
          CONTINUE
        </Button>
      </div>
    </Modal>
  )
}

const useEventListeners = (handler: () => void, dependency: boolean) => {
  const removeEventListeners = () => {
    TRACKED_EVENTS.forEach((event) => {
      window.removeEventListener(event, handler)
    })
  }

  const addEventListeners = () => {
    TRACKED_EVENTS.forEach((event) => {
      window.addEventListener(event, handler, {
        passive: true,
      })
    })
  }

  useEffect(() => {
    if (dependency) {
      removeEventListeners()
      return
    }
    addEventListeners()
    return () => {
      removeEventListeners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency, handler])
}

const useTracker = () => {
  const [isShowingInactiveModal, setIsShowingInactiveModal] = useState(false)
  const [timeUntilLogout, setTimeUntilLogout] = useState(0)

  const onTick = (logoutIn: number) => {
    setTimeUntilLogout(logoutIn)
  }

  const onPrompt = () => {
    setIsShowingInactiveModal(true)
  }

  const onLogout = () => {
    auth.logoutAndLogin()
  }

  const { setInactivityTimeout } = useBackgroundTimeout({
    onPrompt,
    onLogout,
    onTick,
  })

  const onActivity = debounce(() => setInactivityTimeout(), 200, {
    leading: true,
    trailing: true,
  })

  useEventListeners(onActivity, isShowingInactiveModal)

  const closeInactivityModal = () => {
    setIsShowingInactiveModal(false)
  }

  return {
    closeInactivityModal,
    isShowingInactiveModal,
    timeUntilLogout,
    onLogout,
  }
}

const useTitleNotification = (signoutIn: number) => {
  const { t } = useTranslation()
  const originalDocumentTitle = useMemo(
    () => document.title.split(" • ")[0],
    []
  )

  const timeString = formatMilliseconds(signoutIn)
  const notificationTitle = t("activityTracker.INACTIVE_USER_NOTIFICATION", {
    time: timeString,
  })
  usePageTitle(notificationTitle)

  useEffect(() => {
    return () => {
      document.title = `${originalDocumentTitle} • ${DEFAULT_TITLE}`
    }
  }, [originalDocumentTitle])
}

const useCompanySettings = () => {
  const { setMustUseSharedDevice } = useSharedDeviceStore()

  const {
    currentCompany: { companyFeatureFlags },
    currentCompanyError,
  } = useCurrentCompanyContext()
  const { currentUser, currentUserError } = useCurrentUserContext()

  // TODO [error-handling]
  // When an error is encountered, the tracking will be disabled altogether.
  useErrorSentryLog([currentCompanyError, currentUserError])

  const canUseSharedDevice = companyFeatureFlags.includes(
    canUseSharedDeviceFlag
  )
  const mustUseSharedDevice = companyFeatureFlags.includes(
    mustUseSharedDeviceFlag
  )
  const isSharedDeviceEnabled = canUseSharedDevice || mustUseSharedDevice

  useEffect(() => {
    if (mustUseSharedDevice) {
      setMustUseSharedDevice()
    }
  }, [mustUseSharedDevice, setMustUseSharedDevice])

  return {
    currentUser,
    isError: currentCompanyError || currentUserError,
    isSharedDeviceEnabled,
  }
}

export const formatMilliseconds = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 60000)

  const seconds = Math.floor((milliseconds % 60000) / 1000)
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}
