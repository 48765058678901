import { ReactComponent as ChevronIcon } from "assets/icons/chevronIcon.svg"
import kpiIcon from "assets/icons/kpiIcon.svg"
import { formatDate } from "format"
import { capitalize, maxBy } from "lodash-es"
import { Card, Dropdown } from "materia"
import type { PropsWithChildren } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { toPercent } from "utils"
import { custom } from "../../../bonzai/bonzai"
import type { SalesScope } from "../../../customApi/getKpiSales"
import { ConfigError } from "../../../tools/ConfigError"
import { Widget } from "../../Widget"

import s from "./styles.module.scss"

const ENTRIES_PER_PAGE = 5

/**
 * KPI Sales related components (Widget, Modal and Header) are supposed to be
 * used only by company called If, therefore they are highly customized. From
 * what I see it is barely used even by If.
 *
 * Hopefully in the future we can just remove all of that custom logic.
 */
export const KpiSalesWidgetConnected = () => {
  const { scopes, selectedScope, setSelectedScope } = useSalesScopes()

  const sales = custom.getKpiSales.useSuspenseQuery([selectedScope])

  // TODO [widgets-flash] https://jira.attensi.com/browse/WEB-19450
  // Customers requested that this widget disappears when it has no content.
  if (!sales) {
    return null
  }

  const { months, current_month_count, year_count } = sales

  // TODO It seems this component never had loading neither error states.
  // I am not sure if the custom queries used for sales scopes and kpi sales
  // even support loading and error states at the moment - I think they were
  // written to work exclusively with suspense and error boundaries.
  // I will leave it as is now.

  return (
    <KpiSalesWidget
      scopes={scopes}
      selectedScope={selectedScope}
      setSelectedScope={setSelectedScope}
      monthlySales={months}
      currentMonthSales={current_month_count}
      currentYearSales={year_count}
    />
  )
}

type KpiSalesData = { timestamp: string; value: number }

type KpiSalesWidgetProps = {
  scopes: SalesScope[]
  selectedScope: SalesScope
  setSelectedScope: (scope: SalesScope) => void
  monthlySales: KpiSalesData[]
  currentMonthSales: KpiSalesData
  currentYearSales: number
}
export const KpiSalesWidget = ({
  scopes,
  selectedScope,
  setSelectedScope,
  monthlySales,
  currentMonthSales,
  currentYearSales,
}: KpiSalesWidgetProps) => {
  const { t } = useTranslation()

  return (
    <Widget image={kpiIcon} title={t("pageTitle.KPI_SALES")}>
      <div className={s.kpiSales}>
        <ScopeSelect
          scopes={scopes}
          selectedScope={selectedScope}
          onScopeChange={setSelectedScope}
        />
        <KpiWidgetChart months={monthlySales} />
        <KpiSalesWidget.Summary>
          <KpiSalesWidget.SummaryItem
            label={t("kpi.THIS_YEAR")}
            value={currentYearSales}
          />
          <KpiSalesWidget.SummaryItem
            label={t("kpi.THIS_MONTH")}
            value={currentMonthSales.value}
          />
        </KpiSalesWidget.Summary>
      </div>
    </Widget>
  )
}

type ScopeSelectProps = {
  scopes: SalesScope[]
  selectedScope: SalesScope
  onScopeChange: (value: SalesScope) => void
}
const ScopeSelect = ({
  scopes,
  selectedScope,
  onScopeChange,
}: ScopeSelectProps) => {
  const options = scopes.map((scope) => (
    <Dropdown.Option key={scope.type} value={scope}>
      {scope.label}
    </Dropdown.Option>
  ))

  return (
    <Dropdown value={selectedScope} onChange={onScopeChange}>
      <Dropdown.CustomButton>
        <KpiSalesWidget.Header>
          <KpiSalesWidget.Title text={selectedScope.label} />
          <Dropdown.ChevronIcon />
        </KpiSalesWidget.Header>
      </Dropdown.CustomButton>
      <Dropdown.Options>{options}</Dropdown.Options>
    </Dropdown>
  )
}

type KpiWidgetChartProps = {
  months: KpiSalesData[]
}
const KpiWidgetChart = ({ months }: KpiWidgetChartProps) => {
  const { i18n } = useTranslation()

  const {
    maxValue,
    visibleMonths,
    canPaginateLeft,
    canPaginateRight,
    paginateLeft,
    paginateRight,
  } = usePaginatedMonths(months)

  const entries = visibleMonths.map((month, index) => (
    <KpiSalesWidget.Entry
      key={visibleMonths.length - index}
      month={formatMonth(i18n.language, month.timestamp)}
      value={month.value}
      maxValue={maxValue}
    />
  ))

  return (
    <KpiSalesWidget.Chart>
      <KpiSalesWidget.PaginationArrow
        direction="left"
        isDisabled={canPaginateLeft}
        onClick={paginateLeft}
      />
      <KpiSalesWidget.Entries>{entries}</KpiSalesWidget.Entries>
      <KpiSalesWidget.PaginationArrow
        direction="right"
        isDisabled={canPaginateRight}
        onClick={paginateRight}
      />
    </KpiSalesWidget.Chart>
  )
}

const usePaginatedMonths = (months: KpiSalesData[]) => {
  const [page, setPage] = useState(1)

  const startIndex = months.length - ENTRIES_PER_PAGE * page
  const endIndex = startIndex + ENTRIES_PER_PAGE
  const visibleMonths = months.slice(Math.max(0, startIndex), endIndex)
  const maxValue = maxBy(visibleMonths, (month) => month.value)?.value ?? 0

  const canPaginateLeft = startIndex <= 0
  const canPaginateRight = endIndex >= months.length

  const paginateLeft = () => setPage((page) => page + 1)
  const paginateRight = () => setPage((page) => page - 1)

  return {
    maxValue,
    visibleMonths,
    canPaginateLeft,
    canPaginateRight,
    paginateLeft,
    paginateRight,
  }
}

const useSalesScopes = () => {
  const scopes = custom.getSaleScopes.useSuspenseQuery([])
  const firstScope = scopes[0]

  if (firstScope === undefined) {
    throw new ConfigError("No sale scopes")
  }

  const [selectedScope, setSelectedScope] = useState(firstScope)
  return { scopes, selectedScope, setSelectedScope }
}

const formatMonth = (locale: string, timestamp: string) => {
  const month = formatDate(timestamp, locale, { month: "short" })
  return capitalize(month)
}

// COMPONENTS

const Header = ({ children }: PropsWithChildren) => (
  <div className={s.header}>{children}</div>
)

type TitleProps = {
  text: string
}
const Title = ({ text }: TitleProps) => (
  <div className={s.headerTitle}>
    <h2 className={s.headerTitle__text}>{text}</h2>
  </div>
)

const Chart = ({ children }: PropsWithChildren) => (
  <div className={s.chart}>{children}</div>
)

type PaginationArrowProps = {
  direction: "left" | "right"
  isDisabled: boolean
  onClick: () => void
}
const PaginationArrow = ({
  direction,
  isDisabled,
  onClick,
}: PaginationArrowProps) => (
  <button
    className={modifiers(s, "paginationArrow", direction)}
    disabled={isDisabled}
    title={`Arrow ${direction}`}
    onClick={onClick}
  >
    <ChevronIcon className={s.paginationArrow__icon} />
  </button>
)

const Entries = ({ children }: PropsWithChildren) => (
  <div className={s.entries}>{children}</div>
)

type EntryProps = {
  value: number
  maxValue: number
  month: string
}
const Entry = ({ value, maxValue = 100, month }: EntryProps) => (
  <div className={s.entry}>
    <div className={s.entry__value}>{value}</div>
    <div
      className={s.entry__bar}
      style={{ height: `${toPercent(value, maxValue === 0 ? 1 : maxValue)}px` }}
    />
    <div className={s.entry__month}>{month}</div>
  </div>
)

const Summary = ({ children }: PropsWithChildren) => (
  <div className={s.summary}>{children}</div>
)

type SummaryItemProps = {
  value: number
  label: string
}
const SummaryItem = ({ value, label }: SummaryItemProps) => (
  <div className={s.summaryItem}>
    <div className={s.summaryItem__value}>{value}</div>
    <div className={s.summaryItem__label}>{label}</div>
  </div>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton} />
  </Card>
)

KpiSalesWidget.Header = Header
KpiSalesWidget.Title = Title
KpiSalesWidget.Chart = Chart
KpiSalesWidget.PaginationArrow = PaginationArrow
KpiSalesWidget.Entries = Entries
KpiSalesWidget.Entry = Entry
KpiSalesWidget.Summary = Summary
KpiSalesWidget.SummaryItem = SummaryItem
KpiSalesWidget.Skeleton = Skeleton
