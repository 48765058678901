import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query YourJourneysList($limit: Int!, $page: Int) {
    nextJourney {
      id
    }
    productCategories(isJourney: true, limit: $limit, page: $page) {
      pageInfo {
        totalItemCount
      }
      nodes {
        id
        title
        backgroundImageUrl
        isLocked
        progress {
          isCertified
        }
      }
    }
  }
`)
