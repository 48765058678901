import { useAccessParams } from "auth"
import { logoutAndLogin } from "auth/src/login"
import { useGraphqlQuery } from "graphql-mock"
import { useModal, useQueryParam } from "hooks"
import { Dialog, Loader } from "materia"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { withQueryParams } from "utils"
import { ATTENSI_PRODUCT_PLATFORM_FOR_OS } from "../../constants"
import { getVideoLink } from "../../dataUtilities/getVideoLink"
import {
  getPreferredContentLink,
  getPreferredUpload,
} from "../../dataUtilities/productDataUtilities"
import type { ProductUploadPagination } from "../../graphql"
import { createPlaythrough } from "../../graphql/createPlaythrough"
import { useIframeMessages } from "../../tools/useIframeMessages"
import { usePageTitle } from "../../tools/usePageTitle"
import { useBlockConfettiBanner } from "../ConfettiBanner/useBlockConfettiBanner"
import { useSharedDeviceStore } from "../SharedDeviceInactivityTrackerModal/store"
import { ConfirmationModal } from "./components/ConfirmationModal/ConfirmationModal"
import { Iframe } from "./components/Iframe/Iframe"
import { ProductViewModal } from "./components/ProductViewModal/ProductViewModal"
import { VideoPlayer } from "./components/VideoPlayer/VideoPlayer"
import { ViewerModalError } from "./components/ViewerModalError/ViewerModalError"
import { query, type Data } from "./gql"
import { useAutoRotation, useStreamLink, useWebglLink } from "./hooks"
import s from "./styles.module.scss"

// This is a "meta" component
// It's mainly here to fetch and find out which product which type of product we are working with.
// When we know which product, we hand over the responsibility of handling it to the relevant product viewer.
// It's currently coupled with providing the Dialog (background) for the modal, if it becomes an issue we should decouple.
export const ViewerModal = ({
  closeRoute,
  productId,
}: {
  closeRoute: string
  productId: string
}) => {
  useBlockConfettiBanner()

  const { onClose } = useModal(closeRoute)

  const [locale] = useQueryParam("content_locale")

  const { data, error, isPending } = useGraphqlQuery(query, {
    productId,
    platform: ATTENSI_PRODUCT_PLATFORM_FOR_OS,
  })

  usePageTitle(data?.product.title)

  if (error) return <ViewerModalError error={error} onClose={onClose} />

  return (
    <Dialog ariaLabel="Product content" onClose={onClose}>
      <div className={s.content}>
        {isPending ? (
          <Loader />
        ) : (
          <ProductSwitcher data={data} onClose={onClose} locale={locale} />
        )}
      </div>
    </Dialog>
  )
}

// This is a "meta" component
// It's only a wrapper for rendering the correct viewer modal for each product
// The different viewer modals has the responsibility of making their products appear a certain way,
// acquiring the correct URL and applying any special logic that might apply to their product.
const ProductSwitcher = ({
  data,
  onClose,
  locale,
}: {
  data: Data
  onClose: () => void
  locale: string | undefined
}) => {
  const {
    id,
    contentLink,
    playLinks,
    productType,
    productUploads,
    title,
    useTransferToken,
  } = data.product

  switch (productType) {
    case "skill":
      return (
        <SkillsViewer
          title={title}
          productId={id}
          webglLink={playLinks.webglLink}
          useTransferToken={useTransferToken}
          onClose={onClose}
        />
      )
    case "behavior":
    case "operations":
    case "process":
    case "workflow":
    case "act":
      return (
        <WebGLViewer
          title={title}
          productId={id}
          webglLink={playLinks.webglLink}
          useTransferToken={useTransferToken}
          onClose={onClose}
        />
      )
    case "pdf":
      return (
        <PDFViewer
          title={title}
          contentLink={contentLink}
          productUploads={productUploads}
          locale={locale}
          onClose={onClose}
        />
      )
    case "video":
      return <VideoViewer data={data} locale={locale} onClose={onClose} />
    case "xapi":
      return (
        <XapiViewer title={title} contentLink={contentLink} onClose={onClose} />
      )
    case "fast":
    case "quiz_race":
    case "process_on_web":
      return (
        <WebGamesViewer
          title={title}
          contentLink={contentLink}
          onClose={onClose}
        />
      )
    case "assessment": // Also called exam
      return (
        <ExamViewer title={title} contentLink={contentLink} onClose={onClose} />
      )
    case "real_talk":
      return (
        <RealTalkViewer
          playLinks={playLinks}
          productId={id}
          title={title}
          useTransferToken={useTransferToken}
          onClose={onClose}
        />
      )
  }

  throw new Error("Cannot view this product type")
}

const PDFViewer = ({
  title,
  contentLink,
  productUploads,
  onClose,
  locale,
}: {
  title: string | null
  contentLink: string
  productUploads: Pick<ProductUploadPagination, "nodes">
  onClose: () => void
  locale: string | undefined
}) => {
  const src = getPreferredUpload({ productUploads, locale, contentLink })

  return (
    <ProductViewModal title={title} view="fullScreen" onClose={onClose}>
      <Iframe src={src} />
    </ProductViewModal>
  )
}

const RealTalkViewer = ({
  playLinks,
  productId,
  title,
  useTransferToken,
  onClose,
}: {
  // TODO [api-decoupling] Replace with type not tied to Data
  playLinks: Data["product"]["playLinks"]
  productId: Data["product"]["id"]
  title: Data["product"]["title"]
  useTransferToken: Data["product"]["useTransferToken"]
  onClose: () => void
}) => {
  const { i18n } = useTranslation()
  const { streamLink } = playLinks

  const {
    data: src,
    isPending,
    error,
  } = useStreamLink(productId, streamLink, useTransferToken)
  const link = withQueryParams(src ?? "", { locale: i18n.language })

  return (
    <ProductViewModal
      isLoading={isPending}
      view="fullScreen"
      title={title}
      onClose={onClose}
      error={error}
    >
      <Iframe src={link} allow="microphone" />
    </ProductViewModal>
  )
}

// TODO: Clean up and get the dependencies cleaned up and decoupled
const VideoViewer = ({
  data,
  onClose,
  locale,
}: {
  data: Data
  onClose: () => void
  locale: string | undefined
}) => {
  const { product, currentCompany } = data
  const { localeContentLinks, title, contentLink } = data.product

  const [shouldCreatePlaythrough, setShouldCreatePlaythrough] = useState(false)

  const isSkipForwardDisabled =
    currentCompany.companyFeatureFlags.includes("enable_no_skip_videos") &&
    product.noSkip &&
    !product.progress.certified

  const link = getPreferredContentLink({
    localeContentLinks,
    locale,
    contentLink,
  })

  const videoLink = getVideoLink(link)

  const createVideoPlaythrough = () => {
    if (shouldCreatePlaythrough || !isSkipForwardDisabled) {
      createPlaythrough(product.id)
    }
  }

  return (
    <ProductViewModal
      title={title}
      onClose={() => {
        createVideoPlaythrough()
        onClose()
      }}
      view="fullScreen"
    >
      <VideoPlayer
        src={videoLink}
        isSkipForwardDisabled={isSkipForwardDisabled}
        onPlaythroughComplete={() => setShouldCreatePlaythrough(true)}
      />
    </ProductViewModal>
  )
}

const WebGamesViewer = ({
  title,
  contentLink,
  onClose,
}: {
  title: string | null
  contentLink: string
  onClose: () => void
}) => {
  const { i18n } = useTranslation()

  useIframeMessages({
    targetUrl: contentLink,
    onMessage: ({ type }) => {
      if (type === "EXIT_MODAL") onClose()
    },
  })

  const url = withQueryParams(contentLink, { locale: i18n.language })
  const { link, isPending, error } = useAccessParams(url)

  return (
    <ProductViewModal
      title={title}
      onClose={onClose}
      error={error}
      isLoading={isPending}
      view="fullScreen"
    >
      <Iframe src={link} />
    </ProductViewModal>
  )
}

const ExamViewer = ({
  title,
  contentLink,
  onClose,
}: {
  title: string | null
  contentLink: string
  onClose: () => void
}) => {
  const { i18n, t } = useTranslation()

  useIframeMessages({
    targetUrl: contentLink,
    onMessage: ({ type }) => {
      if (type === "EXIT_MODAL") onClose()
    },
  })

  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false)
  const url = withQueryParams(contentLink, { locale: i18n.language })
  const { link, isPending, error } = useAccessParams(url)

  return (
    <>
      {showConfirmCloseModal && (
        <ConfirmationModal
          title={t("confirmCloseModal.QUIT_GAME")}
          description={t("confirmCloseModal.QUIT_EXAM_DESCRIPTION")}
          confirmButtonText={t("actions.QUIT")}
          onConfirm={() => {
            setShowConfirmCloseModal(false)
            onClose()
          }}
          onCancel={() => setShowConfirmCloseModal(false)}
        />
      )}

      <ProductViewModal
        title={title}
        onClose={() => {
          setShowConfirmCloseModal(true)
        }}
        error={error}
        isLoading={isPending}
        view="fullScreen"
      >
        <Iframe src={link} />
      </ProductViewModal>
    </>
  )
}

const XapiViewer = ({
  title,
  contentLink,
  onClose,
}: {
  title: string | null
  contentLink: string
  onClose: () => void
}) => {
  useAutoRotation()

  const { link, isPending, error } = useAccessParams(contentLink)

  return (
    <ProductViewModal
      title={title}
      onClose={onClose}
      isLoading={isPending}
      error={error}
      view="fullScreen"
    >
      <Iframe src={link} />
    </ProductViewModal>
  )
}

// TODO: remove portrait related styles
const WebGLViewer = ({
  title,
  productId,
  webglLink,
  useTransferToken,
  onClose,
}: {
  title: string | null
  productId: string
  webglLink: string | null
  useTransferToken: boolean
  onClose: () => void
}) => {
  const {
    data: src,
    isPending,
    error,
  } = useWebglLink(productId, webglLink, useTransferToken)

  usePlayAttensiProduct()

  return (
    <ProductViewModal
      title={title}
      onClose={onClose}
      error={error}
      isLoading={isPending}
      isFramed={true}
      view="landscape"
    >
      <Iframe src={src} />
    </ProductViewModal>
  )
}

const SkillsViewer = ({
  title,
  productId,
  webglLink,
  useTransferToken,
  onClose,
}: {
  title: string | null
  productId: string
  webglLink: string | null
  useTransferToken: boolean
  onClose: () => void
}) => {
  // const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false)
  const {
    data: src,
    isPending,
    error,
  } = useWebglLink(productId, webglLink, useTransferToken)
  const { t } = useTranslation()

  usePlayAttensiProduct()

  // Skills will transition all their products, over time, to use full screen.
  // We can remove the query param and condition, once its done
  const urlQuery = webglLink?.split("?")[1]
  const view = urlQuery?.includes("fullscreen=false")
    ? "portrait"
    : "fullScreen"

  const { isSharedDevice } = useSharedDeviceStore()

  const srcWithQueryParam = withQueryParams(src || "", {
    shared_device: isSharedDevice,
  })

  useIframeMessages({
    targetUrl: srcWithQueryParam,
    onMessage: ({ type }) => {
      if (type === "LOGOUT") logoutAndLogin()
      if (type === "EXIT_MODAL") onClose()
    },
  })

  return (
    <>
      {/*{showConfirmCloseModal && (*/}
      {/*  <ConfirmationModal*/}
      {/*    title={t("confirmCloseModal.QUIT_GAME")}*/}
      {/*    description={t("confirmCloseModal.QUIT_SKILLS_DESCRIPTION")}*/}
      {/*    confirmButtonText={t("actions.QUIT")}*/}
      {/*    onConfirm={() => {*/}
      {/*      setShowConfirmCloseModal(false)*/}
      {/*      onClose()*/}
      {/*    }}*/}
      {/*    onCancel={() => setShowConfirmCloseModal(false)}*/}
      {/*  />*/}
      {/*)}*/}

      <ProductViewModal
        title={title}
        isLoading={isPending}
        error={error}
        onClose={() => {
          // setShowConfirmCloseModal(true) - re-enable and remove onClose below when enabling this
          onClose()
        }}
        view={view}
        isFramed={view !== "fullScreen"}
      >
        <Iframe src={srcWithQueryParam} />
      </ProductViewModal>
    </>
  )
}

const usePlayAttensiProduct = () => {
  const { setIsPlayingAttensiProduct } = useSharedDeviceStore()

  useEffect(() => {
    setIsPlayingAttensiProduct(true)
    return () => {
      setIsPlayingAttensiProduct(false)
    }
  }, [setIsPlayingAttensiProduct])

  return {}
}
