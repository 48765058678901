import { Link } from "@tanstack/react-location"
import { ReactComponent as ExploreFilledIcon } from "assets/icons/exploreFilledIcon.svg"
import { ReactComponent as ExploreIcon } from "assets/icons/exploreIcon.svg"
import { ReactComponent as ForYouFilledIcon } from "assets/icons/forYouFilledIcon.svg"
import { ReactComponent as ForYouIcon } from "assets/icons/forYouIcon.svg"
import { ReactComponent as JourneyFilledIcon } from "assets/icons/journeyFilledIcon.svg"
import { ReactComponent as JourneyIcon } from "assets/icons/journeyIcon.svg"
import { ReactComponent as LeaderboardIcon } from "assets/icons/leaderboardIcon.svg"
import { ReactComponent as LeaderboardFilledIcon } from "assets/icons/leaderboardsFilledIcon.svg"
import { ReactComponent as NewsFilledIcon } from "assets/icons/newsFilledIcon.svg"
import { ReactComponent as NewsIcon } from "assets/icons/newsIcon.svg"
import GraduationHat from "assets/images/graduationHat.png"
import { sortBy } from "lodash-es"
import { type ComponentProps } from "react"
import { useTranslation } from "react-i18next"
import type { LandingPage } from "../../../graphql"
import { useAIChatContext } from "../../AIChat/providers/AIChatProvider"
import { useCurrentCompanyContext } from "../CurrentCompanyProvider"
import { NavigationSkeleton } from "./loading"
import s from "./styles.module.scss"

type LinkProps = ComponentProps<typeof NavigationItem>
type LinkWithName = LinkProps & {
  name: LandingPage
  isLandingPage: boolean
  isEnabled: boolean
}

type NavigationProps = {
  isLoading: boolean
  showJourneyTab: boolean
  showLeaderboardTab: boolean
  showNewsTab: boolean
  portalLandingPage: string
  showAIChatButton: boolean
}

export const NavigationConnected = () => {
  const {
    currentCompany: { portalFeatureFlags, portalLandingPage, nameKey },
    isCurrentCompanyPending,
  } = useCurrentCompanyContext()
  const companyNamesWithEnabledAIChat = [
    "attensi_university",
    "tomas_myklebust",
  ]
  // TODO: This will be based on a company flag/feature/setting in the future
  // For now we have a hard coded list for a few companies while the back-end features for this is fledged out
  const isChatEnabledForCompany = companyNamesWithEnabledAIChat.includes(
    nameKey ?? ""
  )

  if (portalFeatureFlags.includes("disable_top_menu")) return null

  return (
    <Navigation
      isLoading={isCurrentCompanyPending}
      portalLandingPage={portalLandingPage}
      showJourneyTab={portalFeatureFlags.includes("enable_learning_journey")}
      showLeaderboardTab={!portalFeatureFlags.includes("disable_leaderboard")}
      showNewsTab={portalFeatureFlags.includes("enable_portal_news")}
      showAIChatButton={isChatEnabledForCompany}
    />
  )
}

export const Navigation = ({
  isLoading,
  showJourneyTab,
  showLeaderboardTab,
  showNewsTab,
  portalLandingPage,
  showAIChatButton,
}: NavigationProps) => {
  const { t } = useTranslation()

  const linksWithName: LinkWithName[] = [
    {
      name: "for_you",
      href: "/for-you",
      text: t("navigation.FOR_YOU"),
      Icon: ForYouIcon,
      ActiveIcon: ForYouFilledIcon,
      isLandingPage: portalLandingPage === "for_you",
      isEnabled: true,
    },
    {
      name: "journey",
      href: "/journey",
      text: t("navigation.JOURNEY"),
      Icon: JourneyIcon,
      ActiveIcon: JourneyFilledIcon,
      isLandingPage: portalLandingPage === "journey",
      isEnabled: showJourneyTab,
    },
    {
      name: "explore",
      href: "/explore",
      text: t("navigation.EXPLORE"),
      Icon: ExploreIcon,
      ActiveIcon: ExploreFilledIcon,
      isLandingPage: portalLandingPage === "explore",
      isEnabled: true,
    },
    {
      name: "leaderboard",
      href: "/leaderboards",
      text: t("navigation.LEADERBOARDS"),
      Icon: LeaderboardIcon,
      ActiveIcon: LeaderboardFilledIcon,
      isLandingPage: portalLandingPage === "leaderboard",
      isEnabled: showLeaderboardTab,
    },
    {
      name: "news",
      href: "/news",
      text: t("navigation.NEWS"),
      Icon: NewsIcon,
      ActiveIcon: NewsFilledIcon,
      isLandingPage: portalLandingPage === "news",
      isEnabled: showNewsTab,
    },
  ]

  const enabledLinks = linksWithName.filter(({ isEnabled }) => isEnabled)
  const sortedByLandingPage = sortBy(enabledLinks, ({ isLandingPage }) =>
    isLandingPage ? 0 : 1
  )

  if (isLoading) return <NavigationSkeleton />

  return (
    <div className={s.navigationBar}>
      <nav className={s.navigation}>
        <ul className={s.navigationLinks}>
          {sortedByLandingPage.map(({ ...props }, index) => (
            <NavigationItem key={index} {...props} />
          ))}
        </ul>
      </nav>

      {/* Only testing for Attensi University - will be feature flag in the future */}
      {showAIChatButton && <AIChatButton />}
    </div>
  )
}

export const AIChatButton = () => {
  const { openChat } = useAIChatContext()
  const { t } = useTranslation()

  return (
    <button
      className={s.chatButton}
      onClick={openChat}
      aria-label={t("chat.TITLE")}
    >
      <img src={GraduationHat} className={s.chatButton__image} alt="" />
      <span aria-hidden="true" className={s.chatButton__text}>
        {t("chat.TITLE")}
      </span>
    </button>
  )
}

const NavigationItem = ({
  href,
  text,
  Icon,
  ActiveIcon,
}: {
  href: string
  Icon: React.ElementType
  ActiveIcon: React.ElementType
  text: string
}) => (
  <li>
    <Link
      to={href}
      search={{}}
      className={s.link}
      getActiveProps={getActiveProps}
      aria-label={text}
    >
      {({ isActive }) => (
        <>
          {isActive ? (
            <ActiveIcon className={s.link__icon} alt="" />
          ) : (
            <Icon className={s.link__icon} alt="" />
          )}
          <div className={s.link__text}>{text}</div>
        </>
      )}
    </Link>
  </li>
)

const getActiveProps = () => ({
  className: s["link--active"],
  "aria-current": "page",
})
