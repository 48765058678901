import type { Params, Route } from "@tanstack/react-location"
import { Navigate, Outlet } from "@tanstack/react-location"
import { DiplomaModal } from "./components/DiplomaModal"
import { JourneyNavigation } from "./components/JourneyNavigation"
import { JourneysModal } from "./components/JourneysModal"
import { ProductModal } from "./components/ProductModal"
import { VppModalLoader } from "./components/ProductModal/VppModal/VppModalLoader"
import { RedirectView } from "./components/RedirectView"
import { ViewerModal } from "./components/ViewerModal"
import { KpiSalesModalLoader } from "./components/WidgetLayout/KpiSalesModal/KpiSalesModalLoader"
import { WidgetLayout } from "./components/WidgetLayout/WidgetLayout"
import { WidgetLayoutLoader } from "./components/WidgetLayout/WidgetLayoutLoader"
import type { LocationGenerics } from "./tools/useRouteMetadata"
import { EventsView } from "./views/EventsView"
import { EventHeader } from "./views/EventsView/EventHeader"
import { ExploreView } from "./views/ExploreView"
import { ForYouView } from "./views/ForYouView"
import { RecentActivityWidgetModal } from "./views/ForYouView/RecentActivityWidgetModal"
import { HomeViewConnected } from "./views/HomeView"
import { JourneyNewView } from "./views/JourneyNewView"
import { JourneyView } from "./views/JourneyView"
import { LeaderboardViewResponsive } from "./views/LeaderboardView/LeaderboardViewResponsive"
import { NewsPostViewConnected } from "./views/NewsPostView"
import { NewsViewConnected } from "./views/NewsView/"
import { ProductBundleView } from "./views/ProductBundleView"
import { BundleHeaderConnected } from "./views/ProductBundleView/BundleHeader"
import { SingleJourneyViewConnected } from "./views/SingleJourneyView"
import { UserProfileView } from "./views/UserProfileView"

// TODO https://jira.attensi.com/browse/WEB-19655
// Refactor routes to be more maintanable and easier to read,
// and to allow for customizing layout.
export const getRoutes = (): Route<LocationGenerics>[] => [
  {
    path: "/",
    element: (
      <WidgetLayoutLoader>
        <HomeViewConnected />
      </WidgetLayoutLoader>
    ),
  },

  {
    path: "/for-you",
    element: (
      <WidgetLayoutLoader>
        <ForYouView />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/for-you"),
  },

  {
    path: "/journey",
    element: (
      <WidgetLayoutLoader>
        <JourneyView />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/journey"),
  },

  {
    path: "/_journey_new",
    element: (
      <WidgetLayout widgets={<JourneyNavigation />}>
        <JourneyNewView />
        <Outlet />
      </WidgetLayout>
    ),

    children: [
      {
        path: "/list",
        element: async () => <JourneysModal closeRoute="/_journey_new" />,
      },
      ...getChildRoutes(() => "/_journey_new"),
    ],
  },

  {
    path: "/explore",
    element: (
      <WidgetLayoutLoader>
        <ExploreView />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/explore"),
  },

  {
    path: "/leaderboards",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <LeaderboardViewResponsive />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/leaderboards"),
  },

  {
    path: "/news/post/:newsPostId",
    element: async ({ params }) => (
      <NewsPostViewConnected id={params.newsPostId!} />
    ),
  },

  {
    path: "/news",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <NewsViewConnected />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/news"),
  },

  {
    path: "/events",
    element: (
      <>
        <EventHeader />
        <WidgetLayoutLoader showUserInfoHeader={false}>
          <EventsView />
          <Outlet />
        </WidgetLayoutLoader>
      </>
    ),
    children: getChildRoutes(() => `/events`),
  },

  {
    path: "/bundle/:bundleId",
    element: async ({ params }) => (
      <>
        <BundleHeaderConnected id={params.bundleId!} />
        <WidgetLayoutLoader showUserInfoHeader={false}>
          <ProductBundleView id={params.bundleId!} />
          <Outlet />
        </WidgetLayoutLoader>
      </>
    ),
    children: getChildRoutes((params) => `/bundle/${params.bundleId}`),
  },

  {
    path: "/me",
    element: (
      <>
        <UserProfileView />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "/diploma/:productId",
        element: async ({ params }) => (
          <DiplomaModal closeRoute="/me" productId={params.productId!} />
        ),
      },
      ...getChildRoutes(() => "/me"),
    ],
  },

  // https://jira.attensi.com/browse/WEB-19117
  // A special route for a single journey with no additional elements.
  // Those journeys are supposed to be used as separate SCORM packages in
  // external LMSes.
  {
    path: "/_raw/journey/:journeyId",
    element: async ({ params }) => (
      <>
        <SingleJourneyViewConnected journeyId={params.journeyId!} />
        <Outlet />
      </>
    ),
    children: getChildRoutes((params) => `/_raw/journey/${params.journeyId}`),
    meta: { shouldHideUI: true },
  },

  {
    path: "/redirect",
    element: <RedirectView />,
  },

  {
    element: <Navigate to="/" />,
  },
]

const getChildRoutes = (
  getCloseRoute: (params: Params<string>) => string
): Route<LocationGenerics>[] => [
  {
    path: "/product/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/product/:productId/play",
    element: async ({ params }) => (
      <ViewerModal
        productId={params.productId!}
        closeRoute={getCloseRoute(params)}
      />
    ),
  },

  {
    path: "/product/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="none"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/sim/:productId/vpp",
    element: async ({ params }) => (
      <VppModalLoader
        productId={params.productId!}
        closeRoute={`${getCloseRoute(params)}/sim/${params.productId}`}
      />
    ),
  },

  {
    path: "/sim/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/sim/:productId/play",
    element: async ({ params }) => (
      <ViewerModal
        productId={params.productId!}
        closeRoute={getCloseRoute(params)}
      />
    ),
  },

  {
    path: "/sim/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="leaderboard"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/event/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/event/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="event"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/activity/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/activity/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="activity"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/kpi-sales",
    element: async ({ params }) => (
      <KpiSalesModalLoader closeRoute={getCloseRoute(params)} />
    ),
  },

  {
    path: "/recent-activity-widget",
    element: async ({ params }) => (
      <RecentActivityWidgetModal closeRoute={getCloseRoute(params)} />
    ),
  },
]
