import { ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const YourJourneysListError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()

  return (
    <div className={s.journeyList}>
      <div className={s.error}>
        <ErrorFallback
          error={error}
          title={t("error.GENERIC")}
          message={t("error.JOURNEY_LIST_ERROR")}
        />
      </div>
    </div>
  )
}
