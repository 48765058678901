import { useMatches, type MakeGenerics } from "@tanstack/react-location"

/**
 * react-location allows you to set custom metadata on routes.
 * To properly type this metadata, we have to define
 * LocationGenerics and pass it as generic type to routes
 * passed to Router.
 */
export type LocationGenerics = MakeGenerics<{
  RouteMeta: {
    shouldHideUI: boolean
  }
}>

type RouteParam = keyof LocationGenerics["RouteMeta"]

/**
 * Hook allowing components to access metadata for the current route.
 * This metadata is defined in the routes passed to Router and can be
 * used to alter behaviour of a component depending on the route it
 * lives in.
 */
export const useRouteMetadata = () => {
  // https://github.com/TanStack/router/issues/171
  const matches = useMatches<LocationGenerics>()

  /**
   * Get the value of a requested metadata parameter,
   * if it was defined for the current route.
   */
  const getRouteParam = <T extends RouteParam>(
    paramName: T,
    options: {
      /**
       * If true, the parameter set on a parent route will
       * be applied to all its child routes.
       */
      includeChildRoutes?: boolean
    } = {}
  ): LocationGenerics["RouteMeta"][T] | undefined => {
    const { includeChildRoutes } = options

    // Route matches are ordered from root to leaf.
    // If the param is defined on parent and should be applied to children,
    // we need to check only the first match with the parameter.
    if (includeChildRoutes) {
      const match = matches.find((match) => match.route?.meta?.[paramName])
      return match?.route?.meta?.[paramName]
    }

    // Route matches are ordered from root to leaf.
    // Current route is always the last match, so we need to check it
    // if we are to check the param for the current route only.
    else {
      const lastIndex = matches.length - 1
      return matches[lastIndex]?.route?.meta?.[paramName]
    }
  }

  return { getRouteParam }
}
