import { ReactComponent as ArrowIcon } from "assets/icons/arrowRight.svg"
import { Card, useErrorSentryLog } from "materia"
import { FiestaLink } from "utility-components"

import s from "./styles.module.scss"

type Link = { to: string; ariaLabel: string }
type WidgetProps = {
  image?: string
  link?: Link
  title?: string
  children: React.ReactNode
  error?: unknown
}
export const Widget = ({
  image,
  link,
  title,
  children,
  error,
}: WidgetProps) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  useErrorSentryLog(error)

  const shouldShowHeader = image || title || link

  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        {shouldShowHeader && (
          <WidgetHeader image={image} link={link} title={title} />
        )}
        {children}
      </div>
    </Card>
  )
}

type WidgetHeaderProps = {
  image?: string
  link?: Link
  title?: string
}
const WidgetHeader = ({ image, link, title }: WidgetHeaderProps) => {
  return (
    <div className={s.widget__header}>
      <div className={s.widget__left}>
        {image && <img className={s.widget__image} src={image} alt="" />}
        {title && <div className={s.widget__title}>{title}</div>}
      </div>
      {link && <WidgetLink to={link.to} ariaLabel={link.ariaLabel} />}
    </div>
  )
}

const WidgetLink = ({ to, ariaLabel }: Link) => {
  return (
    <FiestaLink
      className={s.widgetLink}
      to={to}
      // TODO https://jira.attensi.com/browse/WEB-19703
      // We do search={{}} to remove search params from the URL
      // to avoid unexpected behaviours
      search={{}}
      ariaLabel={ariaLabel}
    >
      <ArrowIcon className={s.widgetLink__icon} />
    </FiestaLink>
  )
}
