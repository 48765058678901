import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query UserFilters {
    userProgressSummary {
      productCompleteCount
      productCompleteMax
      productMandatoryCompleteCount
      productMandatoryCompleteMax
    }
  }
`)
