import type { CSSProperties, ReactNode } from "react"
import { isColorContrastAccessible } from "utils"
import { useCurrentCompanyContext } from "../CurrentCompanyProvider"

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const {
    currentCompany: { companyStarColor, companyButtonColor },
  } = useCurrentCompanyContext()

  const style = {
    "--company-star-color": companyStarColor,
    "--company-button-background-color": companyButtonColor,
    "--company-button-text-color":
      getAccessibleTextColorForBackgroundColor(companyButtonColor),
  } as CSSProperties

  return <div style={style}>{children}</div>
}

const getAccessibleTextColorForBackgroundColor = (
  backgroundColor: string | undefined
) => {
  if (backgroundColor === undefined) return ""

  if (isColorContrastAccessible(backgroundColor, "#FFFFFF")) return "#FFFFFF" // White
  if (isColorContrastAccessible(backgroundColor, "#4A4A4A")) return "#4A4A4A" // Coal
  if (isColorContrastAccessible(backgroundColor, "#171717")) return "#171717" // Carbon
  return "#000000" // Black
}
