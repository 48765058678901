import { UserProfileButtonConnected } from "../../UserProfileButton"
import { UserProgressConnected } from "../../UserProgress"
import { Widget } from "../../Widget"
import { UserFiltersConnected } from "./Filters"
import s from "./styles.module.scss"

export const UserWidget = () => {
  return (
    <Widget>
      <UserWidget.Wrapper>
        <UserProfileButtonConnected />
        <UserProgressConnected />
        <UserFiltersConnected />
      </UserWidget.Wrapper>
    </Widget>
  )
}

UserWidget.Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className={s.userWidget}>{children}</div>
)
