import { Link } from "@tanstack/react-location"
import checkmark from "assets/icons/checkmark.svg"
import moreJourneysLine from "assets/images/moreJourneysLine.svg"
import { useGraphqlQuery } from "graphql-mock"
import { Icon } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import type { Journey } from "../../customApi"
import { getCenteredSlice } from "../../tools/getCenteredSlice"
import { YourJourneysListError } from "./error"
import { query } from "./gql"
import { YourJourneysListSkeleton } from "./loading"

import s from "./styles.module.scss"

type YourJourneysListConnectedProps = { size: number }
export const YourJourneysListConnected = ({
  size,
}: YourJourneysListConnectedProps) => {
  const { data, error, isPending } = useGraphqlQuery(query, {
    page: 1,
    limit: 1000, // Big enough number to ensure we get all of 'em
  })

  const journeys = data?.productCategories.nodes ?? []
  const activeJourneyId = data?.nextJourney?.id ?? null

  return (
    <YourJourneysList
      isLoading={isPending}
      error={error}
      journeys={journeys}
      activeJourneyId={activeJourneyId}
      size={size}
    />
  )
}

type YourJourneysListProps = {
  isLoading: boolean
  error: unknown
  journeys: Journey[]
  activeJourneyId: string | null
  size: number
}
export const YourJourneysList = ({
  isLoading,
  error,
  activeJourneyId,
  journeys,
  size,
}: YourJourneysListProps) => {
  const activeJourneyIndex = journeys.findIndex(
    (journey) => journey.id === activeJourneyId
  )

  const {
    items: centeredJourneys,
    itemsBefore,
    itemsAfter,
  } = getCenteredSlice(journeys, activeJourneyIndex, size)

  const getConnectorType = (journey: Journey, index: number): ConnectorType => {
    const isFirst = index === 0

    const isPrevJourneyCompleted =
      centeredJourneys[index - 1]?.progress?.isCertified ?? false
    const isTwoCompletedJourneysInARow =
      journey.progress.isCertified && isPrevJourneyCompleted

    if (isFirst && itemsBefore.length > 0) return "out"

    if (isFirst) return null

    if (isTwoCompletedJourneysInARow) return "linked"

    return "default"
  }

  const hasMoreJourneys = itemsBefore.length > 0 || itemsAfter.length > 0

  const getContent = () => {
    if (isLoading) return <YourJourneysListSkeleton />

    if (error) return <YourJourneysListError error={error} />

    return (
      <>
        {centeredJourneys.map((journey: Journey, index: number) => (
          <JourneyListItem
            journey={journey}
            isActive={journey.id === activeJourneyId}
            connectorType={getConnectorType(journey, index)}
          />
        ))}

        {hasMoreJourneys && (
          <ShowAllButton shouldShowLine={itemsAfter.length > 0} />
        )}
      </>
    )
  }

  return <div className={s.journeyList}>{getContent()}</div>
}

type JourneyListItemProps = {
  journey: Journey
  connectorType: ConnectorType | null
  isActive: boolean
}
const JourneyListItem = ({
  journey,
  connectorType,
  isActive,
}: JourneyListItemProps) => {
  const {
    backgroundImageUrl,
    isLocked,
    title,
    progress: { isCertified },
  } = journey

  return (
    <div className={s.journeyItem}>
      {connectorType && <JourneyConnector type={connectorType} />}

      <button
        className={modifiers(s, "journeyItem__button", {
          isActive,
        })}
      >
        <JourneyThumbnail
          imageUrl={backgroundImageUrl}
          isCertified={isCertified}
          isLocked={isLocked}
        />
        <div
          className={modifiers(s, "journeyItem__title", {
            isActive,
            isCertified,
            isLocked,
          })}
        >
          {title}
        </div>
      </button>
    </div>
  )
}

type JourneyThumbnailProps = {
  imageUrl: string | null
  isCertified: boolean
  isLocked: boolean
}
const JourneyThumbnail = ({
  imageUrl,
  isCertified,
  isLocked,
}: JourneyThumbnailProps) => {
  const getThumbnailImage = () => {
    // A journey can be both locked and completed because reasons.
    // In this case the "locked" state should take precedence over the
    // "completed", because it still blocks the journeys depending on it.
    // If it's neither, show Journey's thumbnail image.
    if (isLocked) return <Icon icon="Lock" />
    if (isCertified) return <img src={checkmark} alt="" />
    return (
      <div
        className={s.journeyItem__thumbnailImage}
        style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
      />
    )
  }

  return (
    <div
      className={modifiers(s, "journeyItem__thumbnail", {
        isCertified,
        isLocked,
      })}
    >
      {getThumbnailImage()}
    </div>
  )
}

type ConnectorType = "linked" | "out" | "default" | null
const JourneyConnector = ({ type }: { type: ConnectorType }) => {
  return (
    <div
      className={modifiers(s, "journeyItem__connector", {
        isLinked: type === "linked",
        isOut: type === "out",
      })}
    />
  )
}

const ShowAllButton = ({ shouldShowLine }: { shouldShowLine: boolean }) => {
  const { t } = useTranslation()

  return (
    <div className={s.showAll__wrapper}>
      <img
        className={modifiers(s, "showAll__image", { shouldShowLine })}
        src={moreJourneysLine}
        alt=""
      />
      <Link to="list" className={s.showAll__button}>
        {t("journey.SHOW_ALL")}
      </Link>
    </div>
  )
}
