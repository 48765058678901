import { useGraphqlQuery } from "graphql-mock"
import { ErrorFallback, Loader } from "materia"
import { useTranslation } from "react-i18next"
import { Journey } from "../JourneyView/Journey"
import { query } from "./gql"

import s from "./styles.module.scss"

type SingleJourneyViewProps = { journeyId: string }
export const SingleJourneyViewConnected = ({
  journeyId,
}: SingleJourneyViewProps) => {
  const { data, error, isPending } = useGraphqlQuery(query, { journeyId })
  const { t } = useTranslation()

  const getContent = () => {
    if (isPending) return <Loader />

    if (error)
      return (
        <ErrorFallback
          title={t("error.GENERIC")}
          message={t("error.JOURNEY_TEASER_FETCH_ERROR")}
          error={error}
        />
      )

    // TODO https://jira.attensi.com/browse/WEB-17783
    // The Journey component will be refactored alongside the
    // JourneyView redesign.
    return <Journey data={data.productCategory} />
  }

  return (
    <div className={s.view}>
      <div className={s.view__journey}>{getContent()}</div>
    </div>
  )
}
