/**
 * @param items Array of items to slice
 * @param centeredItemIndex Index of an item that should be positioned in the middle
 * @param size Size of the output array after the slice
 * @returns Sliced array of items with the active item in the middle
 */
export const getCenteredSlice = <T extends unknown>(
  items: T[],
  centeredItemIndex: number,
  size: number
): { items: T[]; itemsBefore: T[]; itemsAfter: T[] } => {
  const totalCount = items.length
  const halfOfLimit = Math.floor(size / 2)

  // We don't need to slice the array as it's already smaller than passed size
  if (totalCount <= size) {
    return { items, itemsBefore: [], itemsAfter: [] }
  }

  /**
   * TODO
   * Add a detailed description the magic that happens here.
   * Will commit it tomorrow because I've already wrote this
   * but it's on my iPad and I don't want to write all of that
   * all over again because it's long af
   */

  const sliceStart = Math.min(
    Math.max(0, centeredItemIndex - halfOfLimit),
    totalCount - size
  )

  const sliceEnd = sliceStart + size

  const centeredItems = items.slice(sliceStart, sliceEnd)
  const itemsBefore = items.slice(0, sliceStart)
  const itemsAfter = items.slice(sliceEnd)

  return { items: centeredItems, itemsBefore, itemsAfter }
}
