import informationIcon from "assets/icons/informationIcon.svg"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const HowToDownloadText = ({ isVisible }: { isVisible: boolean }) => {
  const { t } = useTranslation()

  if (!isVisible) return null

  return (
    <div className={s.howToDownloadText}>
      <div className={s.howToDownloadText__circle}>
        <img
          className={s.howToDownloadText__icon}
          src={informationIcon}
          alt="Information icon"
        />
      </div>
      <div className={s.howToDownloadText__description}>
        {t("helpText.DESCRIPTION")}
      </div>
    </div>
  )
}
