import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"
import { journeyFragment } from "../JourneyView/Journey/gql"

export type Data = ResultOf<typeof query>

// TODO https://jira.attensi.com/browse/WEB-17783
// This query will be refactored (alongside the Fragment used here)
// with the work on the JourneyView redesign.
export const query = graphql(
  `
    query SingleJourney($journeyId: ID!) {
      productCategory(id: $journeyId) {
        ...Journey
      }
    }
  `,
  [journeyFragment]
)
