import { useWindowWidth } from "materia"
import { useEffect, useRef, type ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { optimizeImage, useOptimizeContext } from "ui-tools"
import { FiestaLink } from "utility-components"
import { Avatar } from "../../Avatar"
import { useCurrentCompanyContext } from "../CurrentCompanyProvider"
import { useCurrentUserContext } from "../CurrentUserProvider"
import { HeaderMenuConnected } from "../HeaderMenu"
import { NavigationConnected } from "../Navigation"

import s from "./styles.module.scss"

export const HeaderConnected = () => {
  const {
    currentCompany: { portalFeatureFlags },
  } = useCurrentCompanyContext()

  const isUserProfileDisabled = portalFeatureFlags.includes(
    "disable_user_profile"
  )

  return (
    <Header
      isUserProfileDisabled={isUserProfileDisabled}
      CompanyLogo={<CompanyLogoConnected />}
      Navigation={<NavigationConnected />}
      UserProfileButton={<UserProfileButtonConnected />}
    />
  )
}

export const Header = ({
  isUserProfileDisabled,
  CompanyLogo,
  Navigation,
  UserProfileButton,
}: {
  isUserProfileDisabled: boolean
  CompanyLogo: ReactNode
  Navigation: ReactNode
  UserProfileButton: ReactNode
}) => {
  const { isBigWindow } = useWindowWidth()
  const { t } = useTranslation()
  const showUserProfileButton = !isUserProfileDisabled && !isBigWindow

  return (
    <header className={s.header}>
      <a href="#main" className={s.header__skipToMainContent}>
        {t("actions.SKIP_TO_MAIN_CONTENT")}
      </a>

      <AppLauncherButton />

      <div className={s.header__container}>
        {CompanyLogo}

        {showUserProfileButton && UserProfileButton}

        {isBigWindow && Navigation}

        <HeaderMenuConnected />
      </div>
    </header>
  )
}

const CompanyLogoConnected = () => {
  const {
    currentCompany: { logoUrl },
    isCurrentCompanyPending,
  } = useCurrentCompanyContext()

  return <CompanyLogo isLoading={isCurrentCompanyPending} src={logoUrl} />
}

type CompanyLogoProps = { isLoading: boolean; src: string }
const CompanyLogo = ({ isLoading, src }: CompanyLogoProps) => {
  const context = useOptimizeContext()

  const optimizeLogo = (targetHeight: number) => {
    const { app, optimizeUrl } = context || {}
    if (!app || !optimizeUrl) return src

    const height = app.heights.find((height) => height >= targetHeight)
    return optimizeImage({ app, optimizeUrl, src, height })
  }

  if (isLoading) {
    return <div className={s.skeleton__logo} />
  }
  return (
    <FiestaLink to="/">
      <img
        className={s.header__logo}
        src={optimizeLogo(50)}
        alt="Company logo"
      />
    </FiestaLink>
  )
}

// TODO: in the future this and the same button in the menu as well as on smaller screens, can be merged to one component with different variants
const UserProfileButtonConnected = () => {
  const {
    currentUser: { avatar, avatarTitle, displayName },
    isCurrentUserPending,
  } = useCurrentUserContext()

  return (
    <UserProfileButton
      isLoading={isCurrentUserPending}
      avatar={avatar}
      avatarTitle={avatarTitle}
      displayName={displayName}
    />
  )
}

type UserProfileButtonProps = {
  isLoading: boolean
  avatar: string
  avatarTitle: string
  displayName: string | null
}
const UserProfileButton = ({
  isLoading,
  avatar,
  avatarTitle,
  displayName,
}: UserProfileButtonProps) => {
  if (isLoading) {
    return (
      <div className={s.header__avatarWrapper}>
        <Avatar.Skeleton className={s.header__avatar} size="medium" />
        <div className={s.skeleton__userDisplayName} />
      </div>
    )
  }

  return (
    <FiestaLink
      className={s.header__avatarWrapper}
      to="/me"
      // TODO https://jira.attensi.com/browse/WEB-19703
      // We do search={{}} to remove search params from the URL
      // to avoid unexpected behaviours
      search={{}}
    >
      <Avatar
        className={s.header__avatar}
        alt={avatarTitle}
        image={avatar}
        size="medium"
      />
      <div className={s.header__userDisplayName}>{displayName}</div>
    </FiestaLink>
  )
}

const AppLauncherButton = () => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      createButton(ref.current)
    }
  }, [])

  return (
    <div className={s.header__appLauncherButton}>
      <div ref={ref} />
    </div>
  )
}

const createButton = async (element: HTMLDivElement) => {
  const { createAppLauncherButton } = await import("@attensi/app-launcher")

  createAppLauncherButton(element)
}

Header.CompanyLogo = CompanyLogo
Header.UserProfileButton = UserProfileButton
