import { useGraphqlQuery } from "graphql-mock"
import { useTranslation } from "react-i18next"
import { Widget } from "../../Widget"
import { Event } from "./Event"
import { query } from "./gql"
import s from "./styles.module.scss"

export const EventWidgetConnected = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  const events = data?.products.nodes ?? []

  return <EventWidget isLoading={isPending} error={error} events={events} />
}

type EventWidgetProps = {
  isLoading: boolean
  error: unknown
  events: EventProduct[]
}
export const EventWidget = ({ isLoading, error, events }: EventWidgetProps) => {
  const { t } = useTranslation()

  const getContent = () => {
    if (isLoading)
      return (
        <>
          <Event.Skeleton />
          <Event.Skeleton />
          <Event.Skeleton />
        </>
      )

    if (error)
      return <p className={s.event}>{t("error.EVENT_WIDGET_FETCH_ERROR")}</p>

    return (
      <>
        {events.map((event, index) => {
          if (!event.course) return null
          return (
            <Event
              key={index}
              eventId={event.id}
              startDatetime={event.course.startDatetime}
              title={event.title}
              isAttending={event.course.isAttending}
            />
          )
        })}
      </>
    )
  }

  // TODO [widgets-flash] https://jira.attensi.com/browse/WEB-19450
  // Customers requested that this widget disappears when it has no content.
  const isEmpty = events.length === 0 && !isLoading && !error
  if (isEmpty) return null

  return (
    <Widget
      title={t("event.UPCOMING_EVENTS")}
      link={{ to: "/events", ariaLabel: "Open event page" }}
      error={error}
    >
      <div className={s.content}>{getContent()}</div>
    </Widget>
  )
}

type EventProduct = {
  id: string
  title: string | null
  course: {
    startDatetime: string
    isAttending: boolean
  } | null
}
