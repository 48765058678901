import userCompletedIcon from "assets/icons/userCompletedIcon.svg"
import userMandatoryIcon from "assets/icons/userMandatoryIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useQueryParam } from "hooks"
import { ProgressCircle, Tooltip } from "materia"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { toPercent } from "utils"
import type { Count } from "../../customApi"
import { useCurrentCompanyContext } from "../AppShell/CurrentCompanyProvider"
import { Stars } from "../Stars"
import { query } from "./gql"

import s from "./styles.module.scss"

// TODO [api-decoupling] https://jira.attensi.com/browse/WEB-19507
// This component was moved into a separate file from UserWidget
// but it was not refactored. Needs to be revisited in the future,
// preferably before it being incorporated into UserProfile.
export const UserProgressConnected = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  const [mandatoryQuery] = useQueryParam("mandatory")
  const isMandatory = mandatoryQuery === "true"

  const { currentCompany, isCurrentCompanyPending } = useCurrentCompanyContext()
  const { portalFeatureFlags } = currentCompany
  const isCompletionProgressEnabled = !portalFeatureFlags.includes(
    "disable_completed_courses_stars"
  )

  const isLoading = isPending && isCurrentCompanyPending

  const { userProgressSummary: progress } = data ?? {}

  const userStars = {
    mandatoryCount: progress?.productMandatoryStarsCount ?? 0,
    mandatoryMax: progress?.productMandatoryStarsMax ?? 0,
    totalCount: progress?.productStarsCount ?? 0,
    totalMax: progress?.productStarsMax ?? 0,
  }
  const userProducts = {
    mandatoryCount: progress?.scenarioMandatoryCompleteCount ?? 0,
    mandatoryMax: progress?.scenarioMandatoryCompleteMax ?? 0,
    totalCount: progress?.scenarioCompleteCount ?? 0,
    totalMax: progress?.scenarioCompleteMax ?? 0,
  }

  // This component is going to be reused in the future in UserProfile
  // Therefore it probably should handle its own feature flag
  if (!isCompletionProgressEnabled) return null

  // It does not look like we've ever cared about the error state
  // of this component and just hid it when encountering one
  if (error) return null

  return (
    <UserProgress
      isLoading={isLoading}
      isMandatory={isMandatory}
      userProducts={userProducts}
      userStars={userStars}
    />
  )
}

type UserProgressProps = {
  isLoading: boolean
  isMandatory: boolean
  userProducts: Count
  userStars: Count
}
export const UserProgress = ({
  isLoading,
  isMandatory,
  userProducts,
  userStars,
}: UserProgressProps) => (
  <div className={s.userProgressChart}>
    <UserProgress.ProductsChart
      isLoading={isLoading}
      isMandatory={isMandatory}
      userProducts={userProducts}
    />
    <UserProgress.StarsChart
      isLoading={isLoading}
      isMandatory={isMandatory}
      userStars={userStars}
    />
  </div>
)

/**
 * Shows user's total completion of training material in PORTAL.
 * It takes completion of every subproduct into consideration instead
 * of just the main product.
 *
 * Differentiates between mandatory and optional training material.
 */
type ProductsChartProps = {
  isLoading: boolean
  isMandatory: boolean
  userProducts: Count
}
const ProductsChart = ({
  isLoading,
  isMandatory,
  userProducts: { mandatoryCount, mandatoryMax, totalCount, totalMax },
}: ProductsChartProps) => {
  const { t } = useTranslation()

  const icon = isMandatory ? userMandatoryIcon : userCompletedIcon
  const variant = isMandatory ? "purple" : "green"
  const title = isMandatory ? t("user.MANDATORY") : t("user.COMPLETED")
  const description = isMandatory
    ? t("user.USER_INFO.mandatory")
    : t("user.USER_INFO.completed")

  const completionCount = isMandatory ? mandatoryCount : totalCount
  const completionMax = isMandatory ? mandatoryMax : totalMax

  if (isLoading) return <UserProgress.SkeletonChart />

  return (
    <ProgressTooltip title={title} description={description}>
      <ProgressContainer
        value={completionCount}
        max={completionMax}
        progressText={`${toPercent(completionCount, completionMax)}%`}
        description={t("user.COMPLETED")}
        variant={variant}
      >
        <img className={s.container__icon} src={icon} alt="" />
      </ProgressContainer>
    </ProgressTooltip>
  )
}

/**
 * Shows user's total earned stars in PORTAL.
 * Differentiates between stars earned via mandatory and optional products.
 */
type StarsChartProps = {
  isLoading: boolean
  isMandatory: boolean
  userStars: Count
}
const StarsChart = ({
  isLoading,
  isMandatory,
  userStars: { totalCount, totalMax, mandatoryCount, mandatoryMax },
}: StarsChartProps) => {
  const { t } = useTranslation()

  const starsCount = isMandatory ? mandatoryCount : totalCount
  const starsMax = isMandatory ? mandatoryMax : totalMax

  if (isLoading) return <UserProgress.SkeletonChart />

  return (
    <ProgressTooltip
      title={t("user.STARS")}
      description={t("user.USER_INFO.stars")}
    >
      <ProgressContainer
        value={starsCount}
        max={starsMax}
        progressText={`${starsCount}/${starsMax}`}
        description={t("user.STARS")}
        variant="companyTheme"
      >
        <div className={s.container__icon}>
          <Stars count={1} max={1} />
        </div>
      </ProgressContainer>
    </ProgressTooltip>
  )
}

const ProgressContainer = ({
  value,
  max,
  progressText,
  description,
  variant,
  children,
}: {
  value: number
  max: number
  progressText: string
  description: string
  variant: "green" | "purple" | "companyTheme"
  children: ReactNode
}) => {
  return (
    <div className={s.container}>
      <div className={s.container__radialContainer}>
        <ProgressCircle value={value} maxValue={max} variant={variant} />
        {children}
      </div>
      <div className={s.container__number}>{progressText}</div>
      <div className={s.container__description}>{description}</div>
    </div>
  )
}

const ProgressTooltip = ({
  title,
  description,
  children,
  isInitiallyVisible,
}: {
  title: string
  description: string
  children: ReactNode
  isInitiallyVisible?: boolean
}) => {
  return (
    <Tooltip initialIsVisible={isInitiallyVisible}>
      <Tooltip.Trigger>{children}</Tooltip.Trigger>
      <Tooltip.Content>
        <div className={s.tooltip}>
          <div className={s.tooltip__title}>{title}</div>
          <div className={s.tooltip__description}>{description}</div>
        </div>
      </Tooltip.Content>
    </Tooltip>
  )
}

UserProgress.SkeletonChart = () => (
  <div className={s.container}>
    <div className={s.skeleton__radialContainer} />
    <div className={s.skeleton__number} />
    <div className={s.skeleton__description} />
  </div>
)
UserProgress.ProductsChart = ProductsChart
UserProgress.StarsChart = StarsChart
