import chevronIcon from "assets/icons/chevronIcon.svg"
import { useTranslation } from "react-i18next"
import { useNavigateBack } from "../../../tools/useNavigateBack"
import { usePageTitle } from "../../../tools/usePageTitle"
import s from "./styles.module.scss"

export const EventHeader = () => {
  const { t } = useTranslation()

  // TODO https://jira.attensi.com/browse/WEB-19512
  // Changing a page title is currently a bit clunky - it needs this hook.
  // EventView is being removed so this is the only component where it makes
  // sense to change the page title.
  usePageTitle(t("pageTitle.ALL_EVENTS"))

  const navigateBack = useNavigateBack()

  return (
    <div className={s.header}>
      <div className={s.header__centered}>
        <div className={s.header__widgetLayoutSize}>
          <button className={s.header__back} onClick={() => navigateBack()}>
            <img className={s.header__backIcon} src={chevronIcon} alt="" />
          </button>
          <div className={s.header__title}>{t("event.ALL_EVENTS")}</div>
        </div>
      </div>
    </div>
  )
}
