import { useLocation, useNavigate } from "@tanstack/react-location"

const initialHistoryLength = window.history.length

export const useNavigateBack = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const navigateBack = () => {
    const isSafeToGoBack = window.history.length > initialHistoryLength
    if (isSafeToGoBack) {
      location.history.back()
    } else {
      navigate({ to: "/" })
    }
  }

  return () => {
    // [TODO] https://jira.attensi.com/browse/WEB-19778
    // We temporarily disable the logic for navigating back based
    // on the browser's history stack, because it's inconsistent
    // if any of the child routes are saved to the history stack.
    //
    // Ex. the initial history stack is 2 - the "New Tab" and the Bundles page.
    // On the Bundles page, we click a product -> we click "Play".
    // The "Play" modal gets saved in the history stack so now the
    // history length is 3. Exiting the "Play" modal does not remove it from
    // the history stack, so it's still 3, even if we are now on the index 1.
    // Therefore isSafeToGoBack returns true but if we do "navigateBack()" we
    // get thrown to the New Tab anyway.
    //
    // navigateBack()

    navigate({ to: "/" })
  }
}
