import adminLogoDarkModeOff from "assets/logos/adminLogoDarkModeOff.svg"
import { auth } from "auth"
import { useTranslation } from "react-i18next"
import { ADMIN_URL } from "../../../../env"
import { IS_APP } from "../../../../vuplex/constants"
import { sendVuplexMessage } from "../../../../vuplex/sendVuplexMessage"
import { BigItem } from "../components"

export const LinkAdmin = () => {
  const { t } = useTranslation()

  return (
    <BigItem
      onClick={openAdmin}
      title={t("settings.MANAGER")}
      icon={adminLogoDarkModeOff}
    />
  )
}

const openAdmin = async () => {
  const link = await auth.addAccessParams(ADMIN_URL)

  if (IS_APP) {
    sendVuplexMessage({
      type: "OPEN_LINK",
      payload: { link, openExternalBrowser: true },
    })
  } else {
    window.location.href = link
  }
}
