import { ErrorFallback, useWindowWidth } from "materia"
import type { ReactNode } from "react"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { backgroundImageStyle, modifiers } from "ui-tools"
import { QueryBoundary } from "utility-components"
import { useRouteMetadata } from "../../tools/useRouteMetadata"
import { ProductMiniModal } from "../ProductMiniModal"
import { useCurrentCompanyContext } from "./CurrentCompanyProvider"
import { HeaderConnected } from "./Header"
import { NavigationConnected } from "./Navigation"
import { NoPortalAccess } from "./NoPortalAccess"
import s from "./styles.module.scss"

export const AppShellConnected = ({ children }: { children: ReactNode }) => {
  const {
    currentCompany: { enabledApps, portalBackgroundUrl, portalFeatureFlags },
    isCurrentCompanyPending,
    currentCompanyError,
  } = useCurrentCompanyContext()

  const { getRouteParam } = useRouteMetadata()
  const shouldHideUI = getRouteParam("shouldHideUI", {
    includeChildRoutes: true,
  })

  const backgroundImage = portalFeatureFlags?.includes(
    "enable_portal_background"
  )
    ? portalBackgroundUrl
    : undefined

  const isPortalDisabled =
    !isCurrentCompanyPending && !enabledApps.includes("portal")

  return (
    <AppShell
      shouldHideUI={shouldHideUI}
      isPortalDisabled={isPortalDisabled}
      error={currentCompanyError}
      backgroundImage={backgroundImage}
    >
      {children}
    </AppShell>
  )
}

export const AppShell = ({
  children,
  isPortalDisabled = false,
  shouldHideUI = false,
  backgroundImage,
  error,
}: {
  children: ReactNode
  isPortalDisabled?: boolean
  shouldHideUI?: boolean
  backgroundImage?: string
  error?: unknown
}) => {
  const { t } = useTranslation()

  const getComponent = () => {
    if (error !== undefined && error !== null)
      return <ErrorFallback error={error} title={t("error.GENERIC")} />
    if (isPortalDisabled) return <NoPortalAccess />
    return children
  }

  // TODO https://jira.attensi.com/browse/WEB-19655
  // Find a more elegant way of disabling UI for specific routes
  if (shouldHideUI)
    return (
      <div className={s.app} style={backgroundImageStyle(backgroundImage)}>
        <QueryBoundary>
          <main id="main" className={s.main}>
            {getComponent()}
          </main>
        </QueryBoundary>
      </div>
    )

  return (
    <div className={s.app} style={backgroundImageStyle(backgroundImage)}>
      <QueryBoundary>
        <HeaderConnected />

        <main id="main" className={s.main}>
          {getComponent()}
        </main>

        <BottomBar />
      </QueryBoundary>
    </div>
  )
}

const BottomBar = () => {
  const isHidden = useIsBottomBarHidden()

  return (
    <div className={modifiers(s, "bottomBar", { isHidden })}>
      <ProductMiniModal />
      <BottomNavigation>
        <NavigationConnected />
      </BottomNavigation>
    </div>
  )
}

const BottomNavigation = ({ children }: { children: ReactNode }) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return <div className={s.bottomNavigationBar}>{children}</div>
}

/**
 * According to a11y guidelines content needs to be accessible in
 * two dimensions as small as 320x256 pixels. Bottom navigation bar
 * takes up a lot of space on such a small screen. To solve, we can
 * show it only when user scrolls in one direction and hide when
 * they scroll in the other.
 * https://www.w3.org/WAI/WCAG22/Techniques/css/C34.html
 */
const useIsBottomBarHidden = () => {
  const [isBottomBarHidden, setIsBottomBarHidden] = useState(false)
  const prevScrollY = useRef(window.scrollY)

  useEffect(() => {
    const onScroll = () => {
      const currentScrollY = window.scrollY
      const isScrollingDown = prevScrollY.current < currentScrollY
      setIsBottomBarHidden(isScrollingDown)
      prevScrollY.current = currentScrollY
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return isBottomBarHidden
}
