import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query YourJourneysStats($limit: Int!, $page: Int) {
    productCategories(isJourney: true, limit: $limit, page: $page) {
      nodes {
        progress {
          certified
          starsCount
        }
      }
    }
  }
`)
