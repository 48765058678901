import { type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query UserProgressChart {
    userProgressSummary {
      # STARS
      productStarsCount
      productStarsMax
      productMandatoryStarsCount
      productMandatoryStarsMax

      # PRODUCTS
      scenarioCompleteCount
      scenarioCompleteMax
      scenarioMandatoryCompleteCount
      scenarioMandatoryCompleteMax
    }
  }
`)
