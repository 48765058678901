import closeIcon from "assets/icons/closeIcon.svg"
import { useModal } from "hooks"
import { Modal } from "materia"
import { YourJourneysListConnected } from "../YourJourneysList"
import { YourJourneysStatsConnected } from "../YourJourneysStats"

import s from "./styles.module.scss"

// Impossibly big number to ensure that all journeys will be shown
export const JOURNEY_LIST_SIZE = 1000

type JourneysModalProps = { closeRoute: string }
export const JourneysModal = ({ closeRoute }: JourneysModalProps) => {
  const { onClose } = useModal(closeRoute)

  return (
    <Modal onClose={onClose} overflowAuto={false} ariaLabel="Diploma">
      <div className={s.content}>
        <CloseButton onClose={onClose} />

        <div className={s.content__wrapper}>
          <div className={s.content__stats}>
            <YourJourneysStatsConnected />
          </div>

          <div className={s.content__list}>
            <YourJourneysListConnected size={JOURNEY_LIST_SIZE} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const CloseButton = ({ onClose }: { onClose: () => void }) => (
  <button className={s.closeButton} onClick={onClose} aria-label="Close">
    <img className={s.closeButton__icon} src={closeIcon} alt="" />
  </button>
)
