import { createConsumer } from "@rails/actioncable"
import { captureException } from "@sentry/react"
import { auth } from "auth"
import { invalidateAllQueries } from "bonzai"
import { useConfettiBannerStore } from "../components/ConfettiBanner/store"
import { BACKOFFICE_API_URL } from "../env"

/**
 * An action cable is a web socket connection to our Ruby back-end
 * It's based on a library unique to Ruby and is intended to facilitate real-time communication between a front-end and a Ruby back-end.
 * The connection is currently used to receive messages regarding a playthrough that has been processed and we use it for firing confetti and updating the FE
 */
export const initializeActionCable = async () => {
  const url = await auth.addAccessParams(`${BACKOFFICE_API_URL}/cable`)
  // Setup web socket connection
  const consumer = createConsumer(url)

  if (consumer === null || consumer === undefined) {
    console.error(
      "An Action Cable web socket instance does not exist. This should never happen."
    )
    captureException(
      new Error("An Action Cable web socket instance does not exist.")
    )
    return
  }

  // Run call back when receiving communication on the channel from back-end
  consumer.subscriptions.create("PlaythroughProcessedChannel", {
    received: async () => {
      const { updateCurrentProductProgress } = useConfettiBannerStore.getState()
      updateCurrentProductProgress()

      invalidateAllQueries()
    },
  })
}
