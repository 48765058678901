import { useSearch } from "@tanstack/react-location"
import { useGraphqlQuery } from "graphql-mock"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink } from "utility-components"
import type { Count } from "../../../../customApi"
import { query } from "./gql"

import s from "./styles.module.scss"

export const UserFiltersConnected = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})
  const {
    productMandatoryCompleteCount = 0,
    productMandatoryCompleteMax = 0,
    productCompleteCount = 0,
    productCompleteMax = 0,
  } = data?.userProgressSummary ?? {}

  return (
    <UserFilters
      isLoading={isPending}
      error={error}
      userFilters={{
        mandatoryCount: productMandatoryCompleteCount,
        mandatoryMax: productMandatoryCompleteMax,
        totalCount: productCompleteCount,
        totalMax: productCompleteMax,
      }}
    />
  )
}

type UserFiltersProps = {
  isLoading: boolean
  error: unknown
  // User's basic product completion. We care only if the main product
  // is completed or not, without taking into account progress of every
  // single subproduct.
  userFilters: Count
}
export const UserFilters = ({
  isLoading,
  error,
  userFilters: { mandatoryCount, mandatoryMax, totalCount, totalMax },
}: UserFiltersProps) => {
  // TODO [error-handling]
  // We didn't have error state for this component before and it just
  // got hidden when an error occurred.
  if (error) return null

  return (
    <div className={s.filters}>
      <IncompleteFilter
        isLoading={isLoading}
        count={totalCount}
        max={totalMax}
      />
      <MandatoryFilter
        isLoading={isLoading}
        count={mandatoryCount}
        max={mandatoryMax}
      />
    </div>
  )
}

type MandatoryFilterProps = { isLoading: boolean; count: number; max: number }
const MandatoryFilter = ({ isLoading, count, max }: MandatoryFilterProps) => {
  const { t } = useTranslation()

  if (isLoading) return <div className={s.skeleton__filter} />

  if (max === 0) return

  return (
    <Filter
      color="pink"
      progress={`${count}/${max}`}
      text={t("user.MANDATORY")}
      param="mandatory"
    />
  )
}

type IncompleteFilterProps = { isLoading: boolean; count: number; max: number }
const IncompleteFilter = ({ isLoading, count, max }: IncompleteFilterProps) => {
  const { t } = useTranslation()

  const incompleteCount = max - count

  if (isLoading) return <div className={s.skeleton__filter} />

  return (
    <Filter
      color="blue"
      progress={`${incompleteCount}`}
      text={t("user.INCOMPLETE")}
      param="incomplete"
    />
  )
}

type FilterProps = {
  progress: string
  text: string
  color: "blue" | "pink"
  param: "mandatory" | "incomplete"
}
const Filter = ({ progress, text, color, param }: FilterProps) => {
  const searchParams = useSearch()

  const isActive = Boolean(searchParams[param])

  return (
    <FiestaLink
      to="/for-you"
      search={{ [param]: isActive ? undefined : true }}
      className={modifiers(s, "filter", color, { isActive })}
    >
      <div className={s.filter__progress}>{progress}</div>
      <div className={s.filter__text}>{text}</div>
    </FiestaLink>
  )
}
